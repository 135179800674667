<!--
 * @Descripttion: 
 * @version: 
 * @Author: gaolongzhi
 * @Date: 2020-08-18 11:12:28
 * @LastEditors: gaolongzhi
 * @LastEditTime: 2020-09-23 11:24:01
-->
<template>
  <el-card header="修改手机号码" class="box-card" shadow="never" style="min-height:750px">
    <el-form
      :model="editTelForm"
      :rules="editTelFormRules"
      ref="editTelForm"
      label-width="100px"
      style="width:80%"
    >
      <el-form-item label="原手机号码" prop="mobile">
        <el-input v-model="editTelForm.mobile" :disabled="true"></el-input>
      </el-form-item>
      <el-form-item label="登录密码" prop="password">
        <el-input type="password" v-model="editTelForm.password" autocomplete="new-password"></el-input>
      </el-form-item>
      <el-form-item label="新手机号" prop="newMobile">
        <el-input v-model="editTelForm.newMobile"></el-input>
      </el-form-item>
      <el-form-item label="验证码" prop="code">
        <el-input v-model="editTelForm.code" placeholder="请输入验证码">
          <template slot="append">
            <el-link type="info" v-if="isOn">({{count}}秒)后重新发送</el-link>
            <el-link type="primary" @click="handleCode" v-else>发送验证码</el-link>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('editTelForm')">提交</el-button>
        <el-button @click="resetForm('editTelForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
import api from "@/api/list";
import { rsaEncrypt } from "@/utils/rsa";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      isOn: false,
      count: 60,
      editTelForm: {
        mobile: "",
        password: "",
        newMobile: "",
        code: "",
        token: "",
      },
      editTelFormRules: {
        password: [
          { required: true, message: "请输入登录密码", trigger: "blur" },
        ],
        newMobile: [
          { required: true, message: "请输入新的手机号", trigger: "blur" },
        ],
        code: [{ required: true, message: "请输验证码", trigger: "blur" }],
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  mounted() {
    this.editTelForm.mobile = this.user.username;
  },
  methods: {
    handleCode() {
      if (this.editTelForm.newMobile == "") {
        this.$message({
          showClose: true,
          message: "请输入新手机号码",
          type: "warning",
        });
        return false;
      } else if (this.editTelForm.newMobile.length !== 11) {
        this.$message({
          showClose: true,
          message: "请输入正确的手机号码",
          type: "warning",
        });
        return false;
      } else if (this.editTelForm.newMobile == this.editTelForm.mobile) {
        this.$message({
          showClose: true,
          message: "新手机号不能与原手机号相同",
          type: "warning",
        });
        return false;
      }

      api
        .mobileValidate(
          {},
          {
            method: "post",
            subUrl: this.editTelForm.newMobile,
          }
        )
        .then((res) => {
          if (res.data) {
            this.$message({
              showClose: true,
              message: "手机号码已存在，请更换其他手机号码",
              type: "warning",
            });
            return false;
          } else {
            api.sendSms({ mobile: this.editTelForm.newMobile }).then((res) => {
              if (res.status == 200) {
                this.editTelForm.token = res.data;
                this.isOn = true;
                let that = this;
                let interval = window.setInterval(function () {
                  --that.count;
                  if (that.count < 0) {
                    that.isOn = false;
                    that.count = 60;
                    window.clearInterval(interval);
                  }
                }, 1000);
              } else {
                this.$message({
                  showClose: true,
                  message: res.data.message,
                  type: "warning",
                });
              }
            });
          }
        });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          api
            .mobileValidate(
              {},
              {
                method: "post",
                subUrl: this.editTelForm.newMobile,
              }
            )
            .then((res) => {
              if (res.data) {
                this.$message({
                  showClose: true,
                  message: "手机号码已存在，请更换其他手机号码",
                  type: "warning",
                });
                return false;
              } else {
                let temp = JSON.parse(JSON.stringify(this.editTelForm));
                // 加密数据
                temp.password = encodeURIComponent(rsaEncrypt(temp.password));
                api.updateMobile(temp, { method: "post" }).then((res) => {
                  if (res.status == 200) {
                    this.isOn = false;
                    this.count = 60;
                    this.$message({
                      showClose: true,
                      message: "操作成功",
                      type: "success",
                    });
                    window.location.reload();
                  } else {
                    this.$message({
                      showClose: true,
                      message: res.message,
                      type: "warning",
                    });
                  }
                });
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.editTelForm.mobile = this.user.username;
    },
  },
};
</script>